export const LOGGING_IN = "LOGGING_IN";
export const LOGGING_IN_INVITE = "LOGGING_IN_INVITE";
export const LOGGING_IN_WITH_GOOGLE = "LOGGING_IN_WITH_GOOGLE";

export const SET_DEVELOPER_MODE = "SET_DEVELOPER_MODE";

export const SET_DEFAULT_EMAILING_INTEGRATION_ID =
  "SET_DEFAULT_EMAILING_INTEGRATION_ID";
export const SET_DEFAULT_CMS_INTEGRATION_ID = "SET_DEFAULT_CMS_INTEGRATION_ID";
export const SET_DEFAULT_ANALYTICS_INTEGRATION_ID =
  "SET_DEFAULT_ANALYTICS_INTEGRATION_ID";

export const USER_MUST_VERIFY_EMAIL = "USER_MUST_VERIFY_EMAIL";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";

export const USER_SET_LANGUAGE = "USER_SET_LANGUAGE";

export const LOGGED_IN = "LOGGED_IN";
export const LOGOUT = "LOGOUT";
export const ERROR_LOGIN = "ERROR_LOGIN";

export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";

export const REGISTERING = "REGISTERING";
export const REGISTERING_INVITE = "REGISTERING_INVITE";

export const ERROR = "ERROR";

export const GET_ACCOUNT_AND_SITE_ID = "GET_ACCOUNT_AND_SITE_ID";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";

export const CREATE_SITE = "CREATE_SITE";

export const SET_CATALOG_ID = "SET_CATALOG_ID";
export const SET_CATALOG_COLUMNS = "SET_CATALOG_COLUMNS";

export const GET_USER_INFOS = "GET_USER_INFOS";
export const SET_USER_INFOS = "SET_USER_INFOS";
export const SET_ROLE = "SET_ROLE";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const SET_CRON = "SET_CRON";

export const CHANGE_SITE = "CHANGE_SITE";
export const CHANGE_THEME = "CHANGE_THEME";

export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";

export const SET_ANALYTICS = "SET_ANALYTICS";

export const SET_PRODUCT_PROPERTIES = "SET_PRODUCT_PROPERTIES";

export const SET_HAS_PAGES = "SET_HAS_PAGES";
export const SET_HAS_CATALOG = "SET_HAS_CATALOG";
export const SET_HAS_ANALYTICS = "SET_HAS_ANALYTICS";
export const SET_HAS_REPORT = "SET_HAS_REPORT";
export const SET_HAS_SKU_REPORT = "SET_HAS_SKU_REPORT";
export const SET_HAS_AB_TEST = "SET_HAS_AB_TEST";
export const SET_HIDE_MERCH = "SET_HIDE_MERCH";
export const SET_HIDE_REPORT = "SET_HIDE_REPORT";
export const SET_HAS_EMOTION_AI = "SET_HAS_EMOTION_AI";

export const GENERATE_RESOURCES = "GENERATE_RESOURCES";
export const RESOURCES_GENERATED = "RESOURCES_GENERATED";

export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const ADD_RUN = "ADD_RUN";
export const REMOVE_RUN = "REMOVE_RUN";

export const ADD_LOOKS_DATA = "ADD_LOOKS_DATA";
export const ADD_SLUG = "ADD_SLUG";

export const SET_CUSTOM_PLAN = "SET_CUSTOM_PLAN";
export const SET_HAS_CONDITIONAL_RULES = "SET_HAS_CONDITIONAL_RULES";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

export const SET_HAS_ALGORITHM_BUILDER = "SET_HAS_ALGORITHM_BUILDER";
