import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";
import { PayloadAction } from "@reduxjs/toolkit";
import produce, { Draft } from "immer";
import {
  CHANGE_SITE,
  CREATE_SITE,
  GENERATE_RESOURCES,
  LOGGED_IN,
  LOGOUT,
  RESOURCES_GENERATED,
  SET_CUSTOM_PLAN,
  SET_DEFAULT_ANALYTICS_INTEGRATION_ID,
  SET_DEFAULT_CMS_INTEGRATION_ID,
  SET_DEFAULT_EMAILING_INTEGRATION_ID,
  SET_HAS_AB_TEST,
  SET_HAS_ALGORITHM_BUILDER,
  SET_HAS_ANALYTICS,
  SET_HAS_CATALOG,
  SET_HAS_CONDITIONAL_RULES,
  SET_HAS_PAGES,
  SET_HAS_REPORT,
  SET_HIDE_MERCH,
  SET_HIDE_REPORT,
  SET_HAS_SKU_REPORT,
  SET_HAS_EMOTION_AI,
  SET_CRON,
} from "../actions/action-types";

export const initialState = {
  siteId: potionsLocalStorage.siteId,
  siteName: potionsLocalStorage.siteName ?? "",
  hasPages: false,
  hasAnalytics: false,
  hasAbtest: false,
  hasSkuReport: false,
  hasCatalog: false,
  hasReport: false,
  hasAlgorithmBuilder: false,
  defaultEmailingIntegrationId: null,
  defaultAnalyticsIntegrationId: null,
  defaultCmsIntegrationId: null,
  shouldGenerateResources: false,
  hasCustomPlan: false,
  hasConditionalRules: false,
  hideMerch: false,
  hideReport: false,
  hasEmotionAi: false,
  cron: "",
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case SET_HAS_CONDITIONAL_RULES:
        draft.hasConditionalRules = action.payload.hasConditionalRules;
        break;
      case SET_CUSTOM_PLAN:
        draft.hasCustomPlan = action.payload.hasCustomPlan;
        break;
      case GENERATE_RESOURCES:
        draft.shouldGenerateResources = true;
        break;
      case RESOURCES_GENERATED:
        draft.shouldGenerateResources = false;
        break;
      case SET_DEFAULT_ANALYTICS_INTEGRATION_ID:
        draft.defaultAnalyticsIntegrationId =
          action.payload.defaultAnalyticsIntegrationId;
        break;
      case SET_DEFAULT_CMS_INTEGRATION_ID:
        draft.defaultCmsIntegrationId = action.payload.defaultCmsIntegrationId;
        break;
      case SET_DEFAULT_EMAILING_INTEGRATION_ID:
        draft.defaultEmailingIntegrationId =
          action.payload.defaultEmailingIntegrationId;
        break;
      case CHANGE_SITE:
        draft.siteId = action.payload.siteId;
        draft.siteName = action.payload.siteName;
        draft.hasPages = false;
        draft.hasAnalytics = false;
        draft.hasAbtest = false;
        draft.hasAlgorithmBuilder = action.payload.hasAlgorithmBuilder;
        draft.hasSkuReport = false;
        draft.hideMerch = false;
        draft.hideReport = false;
        draft.hasEmotionAi = false;
        draft.cron = "";
        break;
      case CREATE_SITE:
        draft.siteId = action.payload.siteId;
        draft.siteName = action.payload.siteName;
        draft.hasPages = false;
        draft.hasAnalytics = false;
        draft.hasAbtest = false;
        draft.hasAlgorithmBuilder = false;
        draft.hasSkuReport = false;
        draft.hideMerch = false;
        draft.hideReport = false;
        draft.hasEmotionAi = false;
        draft.cron = "";
        break;
      case LOGGED_IN:
        draft.siteId = action.payload.siteId;
        draft.siteName = action.payload.siteName;
        draft.hasPages = action.payload.hasPages;
        draft.hasAnalytics = action.payload.hasAnalytics;
        draft.hasAbtest = action.payload.hasAbtest;
        draft.hasSkuReport = action.payload.hasSkuReport;
        draft.hasAlgorithmBuilder = action.payload.hasAlgorithmBuilder;
        draft.shouldGenerateResources = action.payload.shouldGenerateResources;
        draft.cron = action.payload.cron;
        break;
      case LOGOUT:
        draft.siteId = null;
        draft.siteName = "";
        draft.hasPages = false;
        draft.hasAnalytics = false;
        draft.hasAbtest = false;
        draft.hasSkuReport = false;
        draft.defaultEmailingIntegrationId = null;
        draft.shouldGenerateResources = false;
        draft.hideMerch = false;
        draft.hideReport = false;
        draft.hasEmotionAi = false;
        draft.cron = "";
        break;
      case SET_HAS_PAGES:
        draft.hasPages = action.payload.hasPages;
        break;
      case SET_HAS_ANALYTICS:
        draft.hasAnalytics = action.payload.hasAnalytics;
        break;
      case SET_HAS_AB_TEST:
        draft.hasAbtest = action.payload.hasAbtest;
        break;
      case SET_HAS_CATALOG:
        draft.hasCatalog = action.payload.hasCatalog;
        break;
      case SET_HAS_REPORT:
        draft.hasReport = action.payload.hasReport;
        break;
      case SET_HAS_SKU_REPORT:
        draft.hasSkuReport = action.payload.hasSkuReport;
        break;
      case SET_HAS_ALGORITHM_BUILDER:
        draft.hasAlgorithmBuilder = action.payload.hasAlgorithmBuilder;
        break;
      case SET_HIDE_MERCH:
        draft.hideMerch = action.payload.hideMerch;
        break;
      case SET_HIDE_REPORT:
        draft.hideReport = action.payload.hideReport;
        break;
      case SET_HAS_EMOTION_AI:
        draft.hasEmotionAi = action.payload.hasEmotionAi;
        break;
      case SET_CRON:
        draft.cron = action.payload.cron;
        break;
      default:
        break;
    }
  });
}
